import toast, { Toaster } from 'react-hot-toast'
// import ncrypt from 'ncrypt-js'
import ReactLoading from 'react-loading'


// @ts-ignore
export const Carregando = ({ type, color }) => (
    <ReactLoading type={type} color={color} height={'20%'} width={'20%'} />
)

export function notifica() {
    toast('Here is your toast.')
    return (
        <Toaster />
    )
}

export function loadInsertTws() {
    let tws = ''
    const twsTemp = localStorage.getItem('goPanel.tws') || null
    if (twsTemp) return twsTemp
    const letters = 'abcdefghijklmnopqrstuvwxyz'
    const plat_id =
        letters.charAt(Math.floor(Math.random() * letters.length)) +
        (Math.random() + 1).toString(36)
    tws = plat_id
    localStorage.setItem('goPanel.tws', tws)
    return tws
}


export function downloadFile(data, name) {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    return link
}

export function optionsDateFlatPicker(dFormat: string, eTime: boolean = false) {
    return ({
        enableTime: eTime,
        dateFormat: dFormat,
        locale: {
            firstDayOfWeek: 1,
            weekdays: {
                shorthand: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
                longhand: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado']
            },
            months: {
                shorthand: ['Jan', 'Fev', 'Março', 'Abr', 'Maio', 'Junho', 'Julho', 'Ago', 'Sen', 'Out', 'Noé', 'Dez'],
                longhand: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
            }
        }
    })
}

export function currencyFormat(num: number) {
    return `R$ ${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
}
export function mascaraCnpj(cnpj) {
    cnpj = cnpj.replace(/\D/g, '');
    cnpj = cnpj.padStart(14, '0');
    cnpj = cnpj.replace(/(\d{2})(\d)/, '$1.$2');
    cnpj = cnpj.replace(/(\d{3})(\d)/, '$1.$2');
    cnpj = cnpj.replace(/(\d{3})(\d)/, '$1/$2');
    cnpj = cnpj.replace(/(\d{4})(\d{1,2})$/, '$1-$2');
    return cnpj;
}
export function maquinaAtivoInativo(status: number) {
    switch (status) {
        case 0:
            return true
        case 5:
            return true
        default:
            return false
    }
}


function padTo2Digits(num: any) {
    return num.toString().padStart(2, '0')
}

export function functionFormatDate(date: any) {
    const v1 = [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-')
    const v2 = [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(':')
    const newDate = `${v1} ${v2}`
    return newDate
}

export function isDateBr(date: any) {
    const nDate = date.split(' ')
    const v1 = nDate[0].split('/')
    if (v1.length !== 3) return false
    return `${v1[2]}-${v1[1]}-${v1[0]} ${nDate[1]}`
}
// export function Criptografa(texto: string) {
//     // @ts-ignore
//     return ncrypt.encrypt(texto, process.env.REACT_APP_CRYPT_KEY)
// }
// export function Descriptografa(texto: string) {
//     // @ts-ignore
//     return ncrypt.decrypt(texto, process.env.REACT_APP_CRYPT_KEY)
// }
