import mock from '../mock'
// ------------------------------------------------
// GET: Return Emails
// ------------------------------------------------
mock.onGet('/apps/email/emails').reply((config) => {
	const { q = '', folder = 'inbox', label } = config.params

	const queryLowered = q.toLowerCase()

	function isInFolder(email) {
		if (folder === 'trash') return email.folder === 'trash'
		if (folder === 'starred') return email.isStarred && email.folder !== 'trash'
		return email.folder === (folder || email.folder) && email.folder !== 'trash'
	}

	const filteredData = data.emails.filter(
		(email) =>
			/* eslint-disable operator-linebreak, implicit-arrow-linebreak */
			(email.from.name.toLowerCase().includes(queryLowered) ||
				email.subject.toLowerCase().includes(queryLowered)) &&
			isInFolder(email) &&
			(label ? email.labels.includes(label) : true)
	)
	/* eslint-enable  */

	// ------------------------------------------------
	// Email Meta
	// ------------------------------------------------
	const emailsMeta = {
		inbox: data.emails.filter(
			(email) => !email.isDeleted && !email.isRead && email.folder === 'inbox'
		).length,
		draft: data.emails.filter((email) => email.folder === 'draft').length,
		spam: data.emails.filter(
			(email) => !email.isDeleted && !email.isRead && email.folder === 'spam'
		).length
	}

	return [
		200,
		{
			emails: filteredData.reverse(),
			emailsMeta
		}
	]
})

// ------------------------------------------------
// POST: Update Email
// ------------------------------------------------
mock.onPost('/apps/email/update-emails').reply((config) => {
	const { emailIds, dataToUpdate } = JSON.parse(config.data)
	function updateMailData(email) {
		Object.assign(email, dataToUpdate)
	}

	data.emails.forEach((email) => {
		if (emailIds.includes(email.id)) updateMailData(email)
	})

	return [200]
})

// ------------------------------------------------
// POST: Update Emails Label
// ------------------------------------------------
mock.onPost('/apps/email/update-emails-label').reply((config) => {
	const { emailIds, label } = JSON.parse(config.data)

	function updateMailLabels(email) {
		const labelIndex = email.labels.indexOf(label)

		if (labelIndex === -1) email.labels.push(label)
		else email.labels.splice(labelIndex, 1)
	}

	data.emails.forEach((email) => {
		if (emailIds.includes(email.id)) updateMailLabels(email)
	})

	return [200]
})

// ------------------------------------------------
// GET: GET Single Email
// ------------------------------------------------
mock.onGet('/apps/email/get-email').reply((config) => {
	const { id } = config

	const emailId = Number(id)

	const mail = data.emails.find((i) => i.id === emailId)
	const mailIndex = data.emails.findIndex((i) => i.id === mail.id)
	mailIndex === 0
		? (mail.hasPreviousMail = true)
		: (mail.hasPreviousMail = false)
	mailIndex === data.emails.length - 1
		? (mail.hasNextMail = true)
		: (mail.hasNextMail = false)

	return mail ? [200, mail] : [404]
})

// ------------------------------------------------
// GET: Paginate Existing Email
// ------------------------------------------------
mock.onGet('/apps/email/paginate-email').reply((config) => {
	const { dir, emailId } = config.params

	const currentEmailIndex = data.emails.findIndex((e) => e.id === emailId)

	const newEmailIndex =
		dir === 'previous' ? currentEmailIndex - 1 : currentEmailIndex + 1

	const newEmail = data.emails[newEmailIndex]

	return newEmail ? [200, newEmail] : [404]
})
/* eslint-enable */
