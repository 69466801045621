import io from 'socket.io-client'
import { loadInsertTws } from './functions'

// Crie uma função assíncrona para obter o IP
const getIP = async () => {
	const url = process.env.REACT_APP_BASEURL.replace('/panel', '')
	let accessToken = localStorage.getItem('accessToken') ?? ''
	accessToken = accessToken.replace(/"/g, '')
	accessToken = `Bearer ${accessToken}`

	const tws = loadInsertTws()
	const resp = await fetch(`${url}auth/my-ip`, {
		headers: {
			Authorization: accessToken,
			'Content-Type': 'application/json',
			tws
		}
	})
	const json = await resp.json()

	return {
		ip: await json.ip,
		tws
	}
}
// Inicialize o socket após obter o IP
export default getIP().then(({ tws, ip }) => {
	const socket = io(process.env.REACT_APP_SOCKET, {
		reconnection: true,
		reconnectionDelay: 1000,
		reconnectionDelayMax: 5000,
		reconnectionAttempts: Infinity,
		transports: ['websocket'],
		query: {
			ip,
			device: 'unknown',
			ref: null,
			host: 'painel.goingressos.com.br',
			browser: null,
			tws
		}
	})

	socket.on('connect', () => {
		console.warn('connect')
	})

	return socket
})
